import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Document, Page } from 'react-pdf';
import { IconContext } from 'react-icons';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';

const circularButton = css`
  width: 50px;
  height: 50px;
  padding: 6px 0px;
  border-radius: 25px;
  text-align: center;
  line-height: 1.42857;
  @media (max-width: 991px) {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 3px 0px;
    text-align: center;
    line-height: 0;
  }
`;

const centerContentsVertically = css`
  display: flex;
  align-items: center;
`;

const canvas = css`
  @media (max-width: 1199px) {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const headerStyles = css`
  @media (max-width: 767px) {
    & {
      font-size: 1.2rem;
    }
  }
`;

const PDF = ({ url, title }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  return (
    <>
      <Nav
        css={centerContentsVertically}
        className="justify-content-around mb-5"
      >
        <Nav.Item className="d-md-none">
          <Button
            size="lg"
            css={circularButton}
            variant="dark"
            onClick={() =>
              pageNumber !== 1 ? setPageNumber(pageNumber - 1) : null
            }
            className="align-middle"
          >
            <IconContext.Provider
              value={{ color: '#fff', style: { marginBottom: '5px' } }}
            >
              <FaArrowLeft />
            </IconContext.Provider>
          </Button>
        </Nav.Item>
        <h1 className="text-center mb-0" css={headerStyles}>{title}</h1>
        <Nav.Item className="d-md-none">
          <Button
            size="lg"
            css={circularButton}
            variant="dark"
            onClick={() =>
              numPages && pageNumber !== numPages
                ? setPageNumber(pageNumber + 1)
                : null
            }
          >
            <IconContext.Provider
              value={{ color: '#fff', style: { marginBottom: '5px' } }}
            >
              <FaArrowRight />
            </IconContext.Provider>
          </Button>
        </Nav.Item>
      </Nav>
      <h1 className="text-center d-none">{title}</h1>
      <Row>
        <Col md={1} css={centerContentsVertically} className="d-none d-md-flex">
          <Button
            size="lg"
            css={circularButton}
            variant="dark"
            onClick={() =>
              pageNumber !== 1 ? setPageNumber(pageNumber - 1) : null
            }
            className="align-middle"
          >
            <IconContext.Provider
              value={{ color: '#fff', style: { marginBottom: '5px' } }}
            >
              <FaArrowLeft />
            </IconContext.Provider>
          </Button>
        </Col>
        <Col md={10}>
          <Document
            css={canvas}
            file={url}
            loading={
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading PDF...</span>
                </Spinner>
              </div>
            }
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading PDF...</span>
                  </Spinner>
                </div>
              }
            />
          </Document>
          <p className="text-center">
            Page {numPages ? pageNumber : '0'} of {numPages ? numPages : '0'}
          </p>
        </Col>
        <Col md={1} css={centerContentsVertically} className="d-none d-md-flex">
          <Button
            size="lg"
            css={circularButton}
            variant="dark"
            onClick={() =>
              numPages && pageNumber !== numPages
                ? setPageNumber(pageNumber + 1)
                : null
            }
          >
            <IconContext.Provider
              value={{ color: '#fff', style: { marginBottom: '5px' } }}
            >
              <FaArrowRight />
            </IconContext.Provider>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PDF;
