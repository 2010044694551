import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Container from 'react-bootstrap/Container';
import PDF from './pdf';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const content = css`
  min-height: ${contentHeight}vh;
`;

const Screenplay = ({ data: { prismicScreenplay } }) => {
  const { data } = prismicScreenplay;
  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <PDF
          url={data.screenplay_link.url}
          title={data.screenplay_title.text}
        />
      </Container>
    </Layout>
  );
};

export default Screenplay;

export const pageQuery = graphql`
  query ScreenplayBySlug($uid: String!) {
    prismicScreenplay(uid: { eq: $uid }) {
      uid
      data {
        screenplay_title {
          text
        }
        screenplay_link {
          url
        }
      }
    }
  }
`;
